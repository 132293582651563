<template>
    <div :class="`tab ${type}`">
        <button
            v-for="(option, index) in options"
            :key="index"
            :class="`tab__button ${type}${
                option.name === activeButton ? ' active' : ''
            }`"
            @click="handleTabSelect(option)"
        >
            {{ option.name }}
        </button>
    </div>
</template>

<script>
export default {
    name: "Tab",
    props: {
        options: {
            type: Array,
            default: () => [],
            required: true,
        },
        type: {
            type: String,
            default: "round",
        },
        defaultTab: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            activeButton: this.options[0].name,
        };
    },
    methods: {
        handleTabSelect(option) {
            this.activeButton = option.name;
            this.$emit("selected", option);
        },
    },
    watch: {
        defaultTab: {
            handler(value) {
                if (value) this.activeButton = value;
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="scss">
@use "./tab.scss" as *;
</style>
